html,
body {
    height: 100%;
    background-color: #f9fbfd;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
}

label {
    color: rgb(64, 64, 64) !important;
    text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

.white-glow {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.4) !important;
    border-radius: 10px;
}

.white-glow-parent > div {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.4) !important;
    border-radius: 10px;
    padding: 5px !important;
}
