a {
    text-decoration: none;
}

.text-grey {
    color: #6a80a2;
}

.text-bold {
    font-weight: bold;
}

.text-green {
    color: #04d08d;
}

.color-danger {
    color: #d32f2f;
}

.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24em;
    height: 50vh;
    margin-top: 10vh;
    margin-bottom: 100px;
}

.Aligner-item {
    flex: 1;
    max-width: 800px;
}

.Aligner-item--top {
    align-self: flex-start;
}

.Aligner-item--bottom {
    align-self: flex-end;
}

.App {
    text-align: center;
}

.App-logo {
    height: 85px;
}

.App-logo-smaller {
    height: 65px;
}

.App-header {
    height: 200px;
    padding: 20px;
    color: #34495e;
    text-transform: uppercase;
}

.App-intro {
    font-size: large;
}

.App-button {
    width: 400px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.search-label {
    height: 72px;
    position: relative;
    top: -14px;
}

.search-box {
    border: 2px red solid;
    background-color: rgba(255, 255, 255, 0.3);
}

.search-box-empty {
    border: 2px lightgray solid;
    background-color: rgba(255, 255, 255, 0.3);
}

.language-select-button-header {
    color: #203384 !important;
    border: 0 !important;
    font-size: 0.85rem !important;
    text-transform: uppercase;
}

.language-select-button-login {
    color: #ffffff !important;
    border: 0 !important;
    font-size: 0.85rem !important;
    text-transform: uppercase;
}

.contains-tooltip {
    text-decoration: dashed underline lightblue;
}

.footer-container {
    background-color: #21348c;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-content {
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30%;
}
